/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

export default ({ children }) => (
  <Styled.root>
    <header>
      <h2>S&S Playground</h2>
    </header>
    <main>
      <div sx={{ fontFamily: "body" }}>{children}</div>
    </main>
  </Styled.root>
)
