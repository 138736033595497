/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <Styled.a as={Link} to="/sounds/" sx={{ fontSize: 20 }}>
      Sounds
    </Styled.a>
  </Layout>
)

export default IndexPage
